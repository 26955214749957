<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>Конкурсы: {{ categoryTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu v-if="contestSort && contestSort.length > 0" transition="slide-y-transition" left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text tile v-on="on">
            <span class="d-none d-sm-flex mr-1">Сортировка</span>
            <v-icon>$vuetify.icons.sort</v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-list-item-group color="primary">
            <!-- class="active v-list-item--active" -->
            <v-list-item
              v-for="(item, index) in contestSort"
              :key="index"
              :class="item.val === sort ? 'active v-list-item--active' : ''"
              @click="sortCategory(item.val)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
    <v-container>
      <v-row align="center">
        <no-content v-if="!contests || contests.length == 0" route-name title="Записей нет"></no-content>
        <contest-card v-for="(contest, index) in contests" :key="contest.id" :contest="contest" :index="index"></contest-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContestCard from "./ContestCard.vue";

export default {
  components: {
    ContestCard,
  },
  data: () => ({
    categorySlug: "",
    page: 1,
    pages: 0,
    loading: false,
    sort: "all",
    // sortings: [
    //   { text: "Все", val: "all" },
    //   { text: "Ожидает", val: "pending" },
    //   { text: "Прием заявок", val: "recept" },
    //   { text: "Решение жюри", val: "jury" },
    //   { text: "Завершен", val: "completed" }
    // ],

  }),
  methods: {
    getContests() {
      var app = this;

      this.$store
        .dispatch("contest/getContests", {
          sort: app.sort,
          page: app.page,
        })
        .then((response) => {
          // app.loading = false;
          if (response.data.success == 1) {
            app.pages = response.data.contests.last_page;
          }
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          // params: { slug: this.categorySlug },
          query: { page: nextPage, sort: this.sort },
        });
      }
    },
     sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.$router.push({
          name: this.$route.name,
          // params: { slug: this.bookSlug },
          query: { page: 1, sort: this.sort }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      contests: "contest/contests",
      category: "contest/category",
      contestSort: "contest/contestSort",
    }),
    categoryTitle() {
      if (this.category) {
        return this.category.name;
      }
      return "";
    },
  },
  created() {
    //console.log(this.$route.query.page);
    // this.categorySlug = this.$route.params.slug
    //   ? this.$route.params.slug
    //   : "all";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : 'all';
    this.getContests();
  },
};
</script>
