<template>
  <v-col class="text-left" cols="12">
    <v-card class="contest-card" :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''">
      <div v-if="$vuetify.breakpoint.xsOnly" class="head-title-info">
        <div class="contest-status">{{ contest.text_status }}</div>
        <div class="books-count">{{ +contest.count_books }} книг</div>
      </div>
      <div class="contest-long d-flex flex-no-wrap justify-start">
        <div class="contest-image" :to="{name: 'ContestShow', params: {slug: contest.slug}}">
          <router-link :to="{name: 'ContestShow', params: {slug: contest.slug}}">
            <img v-if="contest.image_small" :src="contest.image_small" />
          </router-link>
        </div>
        <div class="contest-long-body">
          <!-- <div class="book-title" ><a :to="{name: 'ContestShow', params: {slug: contest.slug}}">{{ contest.title }}</a></div> -->
          <div class="contest-title">
            <router-link :to="{name: 'ContestShow', params: {slug: contest.slug}}">{{ contest.title }}</router-link>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <div v-if="$vuetify.breakpoint.smAndUp" class="title-info">
              <div class="contest-status">{{ contest.text_status }}</div>
              <div class="books-count">{{ +contest.count_books }} книг</div>
            </div>
          </div>

          <div class="contest-text">
            <div class="contest-text-block prize">
              <div class="head">
                <div v-if="contest.status == 4"><v-icon small>$vuetify.icons.trophy_award</v-icon> Победители</div>
                <div v-else><v-icon small>$vuetify.icons.trophy_award</v-icon> Приз</div>
                </div>
              <div v-html="prizeText" class="body"></div>
            </div>
            <div class="contest-text-block from-to">
              <div class="head"><v-icon small>$vuetify.icons.calendar</v-icon> Срок приема работ</div>
              <div class="body">{{ contest.from_to }}</div>
            </div>
            <div class="contest-text-block date-result">
              <div class="head"><v-icon small>$vuetify.icons.clock_alert_outline</v-icon> Оглашение результатов</div>
              <div class="body">{{ contest.text_date_result }}</div>
            </div>
          </div>

          <v-card-actions v-if="$vuetify.breakpoint.smAndUp" class="mt-auto">
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              :to="{name: 'ContestShow', params: {slug: contest.slug}}"
            >Перейти {{ $vuetify.breakpoint.smAndUp ? 'к работам' : '' }}</v-btn>
            <v-btn
              v-if="showRulesButton"
              color="primary"
              tile
              text
              :to="{name: 'ContestRules', params: {slug: contest.slug}}"
            >Правила конкурса</v-btn>
          </v-card-actions>
        </div>
      </div>
      <v-card-actions v-if="$vuetify.breakpoint.xsOnly" class="mt-auto">
        <v-btn
          small
          color="primary"
          :to="{name: 'ContestShow', params: {slug: contest.slug}}"
        >Перейти {{ $vuetify.breakpoint.smAndUp ? 'к работам' : '' }}</v-btn>
        <v-btn
          small
          v-if="showRulesButton"
          color="primary"
          tile
          text
          :to="{name: 'ContestRules', params: {slug: contest.slug}}"
        >Правила конкурса</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["contest", "index"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
     prizeText(){
      if(this.contest){
        if(this.contest.status == 4 && this.contest.winners_body){
          // return this.contest.winners_body;
          return 'Конкурс завершен';
        }

        return this.contest.prize;
      }
      return '';
    },
    welcomeBooks(){
      //Принмаем заявки
      if(this.contest && this.contest.status == 2 ){
         return true;
      }
      return false;
    },
    showRulesButton(){
      //Показываем ссылку на правила
      if(this.contest && this.contest.status < 3 ){
         return true;
      }
      return false;
    }
  },
};
</script>
